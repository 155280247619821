import { render, staticRenderFns } from "./address-picker.vue?vue&type=template&id=36ddeadc&scoped=true&"
import script from "./address-picker.vue?vue&type=script&lang=js&"
export * from "./address-picker.vue?vue&type=script&lang=js&"
import style0 from "./address-picker.vue?vue&type=style&index=0&id=36ddeadc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ddeadc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mfs/build/project-ics/dev/ics-tenant-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36ddeadc')) {
      api.createRecord('36ddeadc', component.options)
    } else {
      api.reload('36ddeadc', component.options)
    }
    module.hot.accept("./address-picker.vue?vue&type=template&id=36ddeadc&scoped=true&", function () {
      api.rerender('36ddeadc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template/address-picker.vue"
export default component.exports